export const EXPERIENCES = [
  {
    from: "JUN 2024",
    to: "Present",
    companyName: "Holycode (Neon)",
    role: "Medior Angular Developer",
    list: [
      "Refactored the transaction details screen from scratch, adding features like transaction history, tagged transactions, statistics, and category insights.",
      "Contributed to a major migration project, transitioning the app from Cordova to Capacitor, including rewriting in-house Cordova plugins and developing a custom Capacitor plugin for IBAN scanning.",
      "Took part in Angular and Ionic upgrade, migrating from Angular v14 to v18 and Ionic v6 to v8 where my role was to ensure ion-slides are replaced with SwiperJS Web API and there are no bundle issues.",
      "Managed the end-to-end release process, including building for app stores, handling deployments, and ensuring smooth releases.",
    ],
    technologies: [
      "Angular",
      "Ionic",
      "Capacitor",
      "SCSS",
      "TypeScript",
      "JavaScript",
      "HTML",
      "Jenkins",
    ],
    linkUrl: "https://www.holycode.com/",
  },
  {
    from: "FEB 2023",
    to: "JUN 2024",
    companyName: "Holycode (Neon)",
    role: "Junior Angular Developer",
    list: [
      "Developed and maintained the trading feature of a Swiss-made investment platform, the app's most profitable feature, used by tens of thousands of users.",
      "Implemented key functionalities, including portfolio insights, live asset performance, buy/sell flow, and explore section for discovering different kinds of assets.",
      "Built custom performance chart for assets using Highcharts library",
      "Developed an investment plan feature for recurring asset purchases.",
      "Built search functionality with sorting and filtering options.",
      "Refactored keyboard handling into a centralized service for consistent UI behavior across the app.",
      "Standardized app headers by creating a base header component with extendable variations for different screens and documented its usage with all use-cases and possibilities.",
      "Wrote unit and integration tests on the trading feature.",
    ],
    technologies: [
      "Angular",
      "Ionic",
      "Cordova",
      "SCSS",
      "TypeScript",
      "JavaScript",
      "HTML",
    ],
    linkUrl: "https://www.holycode.com/",
  },
  {
    from: "AUG 2022",
    to: "FEB 2023",
    companyName: "Holycode (Neon)",
    role: "Angular Developer Internship",
    list: [
      "Researched and documented the migration process from Cordova to Capacitor, identifying alternative plugins and testing their compatibility with our app.",
      "Developed a blank project from scratch, implementing authentication, authorization, transactions, and payment features similar to our main app.",
      "Optimized the app by replacing Moment.js with date-fns for better tree-shaking and reduced bundle size.",
      "Gained experience with Git, Jira, and Bitbucket, including resolving merge conflicts, writing tickets, and reviewing pull requests (PRs).",
      "Centralized status message logic into a single service, addressing all edge cases and preventing potential pitfalls in the app.",
    ],
    technologies: [
      "Angular",
      "Ionic",
      "Capacitor",
      "SCSS",
      "TypeScript",
      "JavaScript",
      "HTML",
    ],
    linkUrl: "https://www.holycode.com/",
  },
];
