import styled from "styled-components";

export default function Logo() {
  const StyledLink = styled.a`

    &:hover svg path {
      transition: fill 250ms ease-in-out;
      fill: var(--white);
    }
  `;
  return (
    <StyledLink
      href="https://github.com/kosserin"
      target="_blank"
      rel="noreferrer"
      aria-label="Link to GitHub profile"
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.0149 0C8.05313 0 0 8.1125 0 18.1488C0 26.1713 5.15991 32.9622 12.3181 35.3657C13.213 35.5464 13.5409 34.9752 13.5409 34.4948C13.5409 34.074 13.5114 32.6318 13.5114 31.1291C8.50005 32.211 7.45649 28.9656 7.45649 28.9656C6.65114 26.8623 5.45787 26.3217 5.45787 26.3217C3.81767 25.2099 5.57735 25.2099 5.57735 25.2099C7.39675 25.3302 8.35144 27.0729 8.35144 27.0729C9.96177 29.8369 12.5567 29.0561 13.6006 28.5752C13.7496 27.4032 14.2271 26.592 14.7341 26.1414C10.7373 25.7207 6.53204 24.1583 6.53204 17.187C6.53204 15.2039 7.2474 13.5814 8.38093 12.3196C8.2021 11.8689 7.5756 10.0056 8.56015 7.51181C8.56015 7.51181 10.0812 7.03095 13.511 9.37472C14.9794 8.97746 16.4937 8.77536 18.0149 8.77367C19.536 8.77367 21.0865 8.98422 22.5184 9.37472C25.9485 7.03095 27.4697 7.51181 27.4697 7.51181C28.4543 10.0056 27.8273 11.8689 27.6485 12.3196C28.8119 13.5814 29.4978 15.2039 29.4978 17.187C29.4978 24.1583 25.2926 25.6904 21.2658 26.1414C21.9222 26.7122 22.4886 27.7938 22.4886 29.5067C22.4886 31.9404 22.4591 33.8936 22.4591 34.4943C22.4591 34.9752 22.7872 35.5464 23.6818 35.3661C30.84 32.9618 35.9999 26.1713 35.9999 18.1488C36.0294 8.1125 27.9468 0 18.0149 0Z"
          fill="var(--light)"
        />
      </svg>
    </StyledLink>
  );
}
